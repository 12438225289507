<template>
  <v-divider class="sectional-divider mt-6 mb-3"></v-divider>
</template>

<script>
export default {
  name: 'HSectionalDivider'
}
</script>

<style scoped lang="scss">
.sectional-divider {
  //height: 8px;
  border-width: 6px 0 0 0;
  border-radius: 60px;
  border-color: var(--v-primary-base) !important;
  width: 100px;
  //margin: 10px 0;
}
</style>
