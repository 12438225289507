<template>
  <div>
    <h2 class="font-weight-medium grey--text text--darken-2 text-capitalize">
      {{ title }}
    </h2>
    <h4
      class="
        under-title
        font-weight-light
        grey--text
        text--darken-1 text-uppercase
      "
    >
      {{ subtitle }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'HRowTitle',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.under-title {
  font-size: 16px;
}
</style>
