<template>
  <v-card
    class="pa-12 mx-auto rounded-lg"
    :width="$vuetify.breakpoint.xsOnly ? `96%` : `100%`"
    max-width="440"
    height="260"
    max-height="260"
    min-height="260"
    elevation="8"
  >
    <div class="d-flex">
      <div class="testimonial_icon">
        <v-img src="~@/assets/images/quote-open.svg"></v-img>
      </div>
    </div>
    <v-card-text class="text-center">
      <p class="text-body-2 grey--text text--darken-1 ma-0" v-html="quote"></p>
    </v-card-text>
    <div class="d-flex flex-row-reverse">
      <div class="testimonial_icon">
        <v-img src="~@/assets/images/quote-close.svg"></v-img>
      </div>
    </div>
    <div class="text-center d-flex align-center">
      <div class="mr-2 pa-2 h-icon-features">
        <v-img
          :src="require('@/assets/images/' + icon + '.svg')"
          width="48"
        ></v-img>
      </div>
      <div class="d-flex flex-column align-start">
        <span
          class="
            text-body-2
            font-weight-bold
            grey--text
            text--darken-1
            feature-text
            ma-0
            text-capitalize
          "
          >{{ name }}</span
        >
        <span
          class="
            text-caption
            font-weight-light
            grey--text
            text--darken-1
            feature-text
            ma-0
            text-capitalize
          "
          >{{ industry }}</span
        >
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'HTestimonialsCard',
  props: {
    icon: {
      type: String
    },
    quote: {
      type: String
    },
    name: {
      type: String
    },
    industry: {
      type: String
    },
    hover: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.h-icon-features {
  //background-color: var(--v-primary-lighten5);
  border-radius: 100%;
}

//.feature-text {
//  font-size: 16px;
//}
</style>
