<template>
  <div :class="['h-phone-input', wrapperClasses]">
    <div class="country-code">
      <v-select
        :disabled="disabled"
        :outlined="outlined"
        :filled="filled"
        :flat="flat"
        :light="light"
        :shaped="shaped"
        :rounded="rounded"
        :background-color="backgroundColor"
        :dense="dense"
        v-model="countryCode"
        @change="onChangeCountryCode"
        :hide-details="true"
        :label="selectLabel"
        :items="sortedCountries"
        :menu-props="dropMenuProps"
        :append-icon="dropIcon"
        item-text="name"
        item-value="iso2"
        return-object
      >
        <template v-slot:selection>
          <div :class="activeCountry.iso2.toLowerCase()" class="vti__flag" />
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </div>
    <ValidationProvider
      :name="$attrs.name"
      :rules="rules"
      :mode="mode"
      v-slot="{ errors }"
      class="test"
    >
      <v-text-field
        :error-messages="errors"
        :disabled="disabled"
        :outlined="outlined"
        :filled="filled"
        :flat="flat"
        :light="light"
        :shaped="shaped"
        :rounded="rounded"
        :background-color="backgroundColor"
        :dense="dense"
        ref="input"
        type="tel"
        v-model="phone"
        v-bind="$attrs"
        v-on="{
          ...$listeners,
          input: onInput
        }"
        @keyup.enter="onEnter"
        @keyup.space="onSpace"
      >
        <!-- v-on="$listeners"  it's been used 2 times -->
        <!-- pass through scoped slots -->
        <template
          v-for="(_, scopedSlotName) in $scopedSlots"
          v-slot:[scopedSlotName]="slotData"
        >
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>
      </v-text-field>
    </ValidationProvider>
  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import countriesMixin from '@/mixins/countriesMixin'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'HPhoneInput',
  components: {
    ValidationProvider
  },
  mixins: [countriesMixin],
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    mode: {
      type: String,
      validator: (value) => {
        // Only accepts values available: 'aggressive', 'lazy', 'passive', 'eager'
        return value.match(/(aggressive|lazy|passive|eager|custom)/)
      },
      default: 'aggressive'
    },
    backgroundColor: {
      type: String
    },
    filled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    shaped: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dropIcon: {
      type: String
    },
    selectLabel: {
      type: String,
      default: ''
    },
    dropMenuProps: {
      type: [String, Array, Object],
      default: () => {}
    },
    disabledFetchingCountry: {
      type: Boolean,
      default: false
    },
    parsedMode: {
      type: String,
      default: 'international',
      validator: (value) => ['international', 'national'].includes(value)
    },
    wrapperClasses: {
      type: [String, Array, Object],
      default: () => ''
    },
    inputOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      phone: '',
      activeCountry: { iso2: '' },
      countryCode: null
    }
  },
  computed: {
    phoneObject() {
      const result = PhoneNumber(
        this.phone || '',
        this.activeCountry.iso2
      ).toJSON()
      Object.assign(result, {
        isValid: result.valid,
        country: this.activeCountry
      })
      if (!this.phone) {
        return {
          ...result,
          number: {
            input: ''
          }
        }
      }
      return result
    },
    phoneText() {
      let key = 'input'
      if (this.phoneObject.valid) {
        key = this.parsedMode
      }
      return this.phoneObject.number[key] || ''
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    //'phoneObject.valid': function (value) {
    //  if (value) {
    //    this.phone = this.phoneText
    //  }
    //  this.$emit('validate', this.phoneObject)
    //},
    //value() {
    //  this.phone = this.value
    //},
    phone(newValue) {
      if (newValue) {
        if (newValue[0] === '+') {
          const code = PhoneNumber(newValue).getRegionCode()
          if (code) {
            this.activeCountry = this.findCountry(code) || this.activeCountry
          }
        }
      }
      //console.log(this.phoneObject)
      this.$emit(
        'input',
        this.phoneObject.number.international,
        this.phoneObject
      )
    },
    activeCountry(value) {
      if (value && value.iso2) {
        this.$emit('country-changed', value)
      }
    }
  },
  methods: {
    choose(country, toEmitInputEvent = false) {
      this.activeCountry = country || this.activeCountry || {}
      if (
        this.phone &&
        this.phone[0] === '+' &&
        this.activeCountry.iso2 &&
        this.phoneObject.number.significant
      ) {
        // Attach the current phone number with the newly selected country
        this.phone = PhoneNumber(
          this.phoneObject.number.significant,
          this.activeCountry.iso2
        ).getNumber('international')
      } else if (
        this.inputOptions &&
        this.inputOptions.showDialCode &&
        country
      ) {
        // Reset phone if the showDialCode is set
        this.phone = `+${country.dialCode}`
      }
      if (toEmitInputEvent) {
        this.$emit('input', this.phoneText, this.phoneObject)
      }
    },
    initializeCountry() {
      return new Promise((resolve) => {
        // (1) If the phone included prefix (+12), try to get the country and set it
        if (this.phone && this.phone[0] === '+') {
          const activeCountry = PhoneNumber(this.phone).getRegionCode()
          if (activeCountry) {
            this.choose(activeCountry)
            resolve()
            return
          }
        }

        // (2) Use default country if passed from parent
        if (this.defaultCountry) {
          const defaultCountry = this.findCountry(this.defaultCountry)
          if (defaultCountry) {
            this.choose(defaultCountry)
            resolve()
            return
          }
        }
        const fallbackCountry =
          this.findCountry(this.preferredCountries[0]) ||
          this.filteredCountries[0]

        // (3) Check if fetching country based on user's IP is allowed, set it as the default country
        if (!this.disabledFetchingCountry) {
          this.getCountryFromIP()
            .then((res) => {
              if (this.phone === '') {
                this.activeCountry = this.findCountry(res) || this.activeCountry
              }
            })
            .catch((error) => {
              console.warn(error)
              // (4) Use the first country from preferred list (if available) or all countries list
              this.choose(fallbackCountry)
            })
            .finally(() => {
              resolve()
            })
        } else {
          // 5) Use the first country from preferred list (if available) or all countries list
          this.choose(fallbackCountry)
          resolve()
        }
      })
    },

    onInput(e) {
      console.log(e)
      // Returns response.number to assign it to v-model (if being used)
      // Returns full response for cases @input is used
      // and parent wants to return the whole response.
      this.$emit('input', this.phoneText, this.phoneObject)
    },
    onEnter() {
      this.$emit('enter')
    },
    onSpace() {
      this.$emit('space')
    },
    focus() {
      this.$refs.input.focus()
    },
    onChangeCountryCode() {
      this.choose(this.countryCode, true)
    }
  },
  mounted() {
    this.initializeCountry()
      .then(() => {
        if (
          !this.phone &&
          this.inputOptions &&
          this.inputOptions.showDialCode &&
          this.activeCountry.dialCode
        ) {
          this.phone = `+${this.activeCountry.dialCode}`
        }
        this.countryCode = this.activeCountry
        //this.$emit('validate', this.phoneObject)
      })
      .catch(console.error)
  },
  created() {
    if (this.value) {
      this.phone = this.value.trim()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/extra/countriesSprite.css';
.vti__flag {
  margin-right: 8px;
}

.h-phone-input {
  display: flex;
  //align-items: center;

  .country-code::v-deep {
    min-width: 120px; //75px;
    max-width: 120px; //75px;
    width: 120px; //75px;
    margin-right: 24px;

    & .v-icon {
      height: 16px;
      width: 16px;
    }
  }

  .test {
    width: 100%;
    display: flex;
  }
  li.last-preferred {
    border-bottom: 1px solid #cacaca;
  }

  .v-text-field {
    .v-select__selections {
      position: relative;
      .vti__flag {
        position: absolute;
        margin-left: 18px;
      }
    }
    &--outlined {
      .v-select__selections {
        .vti__flag {
          margin-left: auto;
        }
      }
    }
  }
}
</style>
