<template>
  <div>
    <vue-typed-js
      :strings="words"
      :loop="true"
      :showCursor="false"
      :typeSpeed="100"
      :backSpeed="100"
    >
      <div
        :class="
          $vuetify.breakpoint.mdAndDown
            ? ['h-type-cont', 'd-flex', 'justify-center', 'align-center']
            : ''
        "
      >
        <h2
          class="font-weight-medium grey--text text--darken-2"
          v-html="title"
        />
      </div>
    </vue-typed-js>

    <h4
      class="
        under-title
        font-weight-light
        grey--text
        text--darken-1 text-uppercase
      "
    >
      {{ subtitle }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'HRowTitleTyped',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    words: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
.under-title {
  font-size: 16px;
}
.h-type-cont {
  height: 108px; //72px;
}
</style>
