<template>
  <ValidationProvider
    :name="$attrs.name"
    :rules="rules"
    :mode="mode"
    v-slot="{ errors }"
  >
    <v-text-field
      v-model="innerValue"
      :class="fieldClass"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!-- v-on="$listeners"  it's been used 2 times -->
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
  name: 'HTextField',
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: ''
  }),
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    mode: {
      type: String,
      validator: (value) => {
        // Only accepts values available: 'aggressive', 'lazy', 'passive', 'eager'
        return value.match(/(aggressive|lazy|passive|eager|custom)/)
      },
      default: 'aggressive'
    },
    // This must be included in props
    value: {
      type: null
    },
    fieldClass: {
      type: String,
      default: ''
    }
  },
  watch: {
    // Handle internal model changes
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handle external model changes
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style scoped lang="scss"></style>
