<template>
  <v-card class="pa-8 rounded-xl" color="primary lighten-5" elevation="0">
    <v-card-text>
      <ValidationObserver v-slot="{ passes, valid }" ref="formObs">
        <v-form
          ref="formContact"
          :input="isValid(valid)"
          method="post"
          @submit.prevent="passes(submitForm)"
          class="h-contact-form"
        >
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <HTextField
                :name="getName(labels.firstName)"
                :label="labels.firstName"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="primary"
                mode="eager"
                v-model="models.firstName"
                rules="required|max:50"
                placeholder="e.g. John/Jay"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <HTextField
                :name="getName(labels.lastName)"
                :label="labels.lastName"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="primary"
                mode="eager"
                v-model="models.lastName"
                rules="required|max:50"
                placeholder="e.g. Doe"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <HTextField
                :name="getName(labels.email)"
                :label="labels.email"
                type="email"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="grey darken-2"
                mode="eager"
                v-model="models.email"
                rules="required|email"
                placeholder="e.g. j.doe@globex.com"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <HPhoneInput
                :name="getName(labels.phone)"
                :label="labels.phone"
                outlined
                rounded
                full-width
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="grey darken-2"
                placeholder="e.g. 212-312-5634"
                :dropIcon="'$chevronDown'"
                @input="phoneInput"
                rules="required|max:25"
                :dropMenuProps="{
                  auto: $vuetify.breakpoint.xsOnly,
                  rounded: 'lg',
                  maxHeight: 260,
                  maxWidth: 420,
                  fixed: true,
                  contentClass: 'country-menu'
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <HTextField
                :name="getName(labels.jobTitle)"
                :label="labels.jobTitle"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="grey darken-2"
                mode="eager"
                v-model="models.jobTitle"
                rules="required|max:50"
                placeholder="e.g. Head of Division"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <HTextField
                :name="getName(labels.companyName)"
                :label="labels.companyName"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2 required"
                color="grey darken-2"
                mode="eager"
                v-model="models.companyName"
                rules="required|max:50"
                placeholder="e.g. Globex Inc."
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <HTextField
                :name="getName(labels.companyWebsite)"
                :label="labels.companyWebsite"
                outlined
                rounded
                hide-details="auto"
                class="my-0 text-body-2"
                color="grey darken-2"
                mode="eager"
                v-model="models.companyWebsite"
                rules="max:50"
                placeholder="e.g. http://www.website.com"
              />
            </v-col>
          </v-row>

          <v-divider class="my-8" />

          <div class="mb-8">
            <HCheckbox
              color="primary"
              v-model="models.permission"
              :name="getName(labels.permission)"
              hide-details="auto"
              :ripple="false"
              class="h-checkbox required"
              :rules="{ permission: { allowFalse: false } }"
            >
              <template v-slot:label>
                {{ labels.permission }}
              </template>
            </HCheckbox>
          </div>

          <v-btn
            type="submit"
            depressed
            block
            rounded
            x-large
            color="primary"
            class="mb-8"
            :loading="loading"
            >Sign Up</v-btn
          >
        </v-form>
      </ValidationObserver>

      <div class="h-form-info">
        {{ formInfo }}
        <router-link :to="`/${formInfoLink.url}`" class="h-link">
          <b>{{ formInfoLink.text }}</b
          >.
        </router-link>
      </div>

      <v-snackbar
        text
        v-model="snackbar"
        :multi-line="true"
        :timeout="38000"
        color="success"
        :elevation="8"
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="success"
            class="text-body-2"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import HTextField from '@/components/reusables/HTextField'
import HCheckbox from '@/components/reusables/HCheckbox'
import HPhoneInput from '@/components/reusables/HPhoneInput'
export default {
  name: 'HContactForm',
  components: {
    HTextField,
    HCheckbox,
    HPhoneInput,
    ValidationObserver
  },
  data: () => ({
    snackbar: false,
    text: `Successfully Submitted`,
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      phone: ' Phone Number',
      jobTitle: 'Job Title',
      companyName: 'Company Name',
      companyWebsite: 'Company Website',
      permission: 'I agree to receive messaging from Hyla'
    },
    models: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      countryName: '',
      jobTitle: '',
      companyName: '',
      companyWebsite: '',
      permission: false
    },
    formInfo: `We're committed to your privacy. Hyla uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our `,
    formInfoLink: {
      text: 'Privacy Policy',
      url: 'privacy'
    },
    loading: false
  }),
  methods: {
    phoneInput(formattedNumber, { number, country }) {
      this.models.phone = number.international
      this.models.countryName = country && country.name
    },
    getName(label) {
      return Object.keys(this.labels).find((key) => this.labels[key] === label)
    },
    isValid(valid) {
      return this.$emit('disabled', !valid)
    },
    trackSubmitEvent() {
      this.$gtag.event('sign_up', {
        event_category: 'engagement',
        event_label: 'method',
        value: this.models.email
      })
    },
    gtmTrackSubmitEvent() {
      this.$gtm.trackEvent({
        event: 'form_submission',
        category: 'Form Submission',
        action: 'submit',
        label: 'New Subscriber',
        dataEvent: new Date(Date.now()),
        value: this.models.email
      })
    },
    submitForm() {
      console.log(this.models)
      this.loading = true
      this.axios
        .post('/add-prospect', this.models)
        .then((response) => {
          this.trackSubmitEvent()
          this.gtmTrackSubmitEvent()
          this.$refs.formContact.reset()
          this.$refs.formObs.reset()
          this.snackbar = true
          console.log(response.data)
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.formObs.setErrors(err.response.data.errors)
          } else {
            this.$router.push({ name: 'Error' })
          }
          //console.log('Error', err.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.country-menu {
  z-index: 1 !important;
}
.h-contact-form::v-deep {
  & .v-input__slot {
    background: rgba(0, 55, 138, 0.06) !important;
  }

  & .h-checkbox {
    //margin-bottom: 32px !important;
    & .v-icon__component {
      height: 20px;
      width: 20px;
    }

    & .v-input__slot:not(.h-checkbox) {
      background: transparent !important;
    }
  }

  & .required {
    & .v-label:not(.v-label--active) {
      &:after {
        content: ' *';
      }
    }
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover {
      -webkit-box-shadow: 0 0 0 30px rgba(0, 55, 138, 0.06) inset !important;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
    }
    &:-webkit-autofill::first-line {
      font-family: $body-font-family;
      font-size: 1rem;
    }
  }
}

.h-form-info::v-deep {
  & a {
    color: var(--v-grey-darken2) !important;
    text-decoration: none !important;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
