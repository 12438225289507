import countriesData from '@/constants/countriesData'
export default {
  props: {
    onlyCountries: {
      type: Array,
      default: () => []
    },
    ignoredCountries: {
      type: Array,
      default: () => []
    },
    preferredCountries: {
      type: Array,
      default: () => []
    },
    defaultCountry: {
      // Default country code, ie: 'AU'; Will override the current country of the user
      type: String,
      default: ''
    }
  },
  data: () => ({}),
  computed: {
    allCountries() {
      return countriesData
    },
    filteredCountries() {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.getCountries(this.onlyCountries)
      }
      if (this.ignoredCountries.length) {
        return this.allCountries.filter(
          ({ iso2 }) =>
            !this.ignoredCountries.includes(iso2.toUpperCase()) &&
            !this.ignoredCountries.includes(iso2.toLowerCase())
        )
      }
      return this.allCountries
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries).map(
        (country) => ({ ...country, preferred: true })
      )
      return [...preferredCountries, ...this.filteredCountries]
    }
  },
  methods: {
    /**
     * Find countries from the list using iso2 code
     * @param iso
     * @return {{dialCode: string, name: string, areaCodes, iso2: string, priority}}
     */
    findCountry(iso = '') {
      return this.allCountries.find(
        (country) => country.iso2 === iso.toUpperCase()
      )
    },
    /**
     * Get the list of countries from the list of iso2 code
     * @param list
     * @return {{dialCode: string, name: string, areaCodes, iso2: string, priority}[]}
     */
    getCountries(list = []) {
      return list
        .map((countryCode) => this.findCountry(countryCode))
        .filter(Boolean)
    },
    /**
     * Get the user country using the IP address
     * @return {Promise<string>}
     */
    getCountryFromIP() {
      return this.axios
        .get('https://ip2c.org/s', { headers: null })
        .then((response) => {
          const result = (response.data || '').toString()

          if (!result || result[0] !== '1') {
            throw new Error('unable to fetch the country')
          }

          return result.substr(2, 2)
        })
    }
  }
}
