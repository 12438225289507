<template>
  <div class="mb-4">
    <p
      :class="paragraphHighlight(paragraph.highlight)"
      v-for="(paragraph, index) in paragraphs"
      :key="index"
    >
      {{ paragraph.text }}
    </p>
  </div>
</template>

<script>
//import { capitalizeOnlyFirst } from '@/helpers/utils'
export default {
  name: 'HRowParagraphs.vue',
  props: {
    paragraphs: {
      type: Array
    }
  },
  methods: {
    //paragraphFormatted(paragraph) {
    //  return capitalizeOnlyFirst(paragraph)
    //},
    paragraphHighlight(paragraph) {
      return paragraph
        ? 'primary--text font-weight-medium mb-3'
        : 'grey--text text--darken-2 mb-2'
    }
  }
}
</script>

<style scoped lang="scss"></style>
